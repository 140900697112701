export const nav = (poe, storagelessDevice, structure) => ([
  {
    'automation-id': 'navlink-sidebar-dashboard',
    icon: 'fa-tachometer-alt',
    message: 'layout.es.sidebar.dashboard',
    url: '/dashboard',
  },
  {
    title: true,
    message: 'common.apps',
  },
  ...(structure ? Object.entries(structure).map((service) => ({
    'automation-id': `navlink-sidebar-${service[0]?.replaceAll('.', '-')}${service[1].component ? `-${service[1].component.replaceAll('.', '-')}` : ''}`,
    id: service[0],
    hidden: service[1].hidden ?? false,
    message: service[1].name,
    icon: service[1].icon,
    url: `/apps/${service[0]}`,
    children: (service[1].members
      ? service[1].members
        .sort((a, b) => (a.id < b.id
          ? -1
          : (a.id > b.id
            ? 1
            : (a.component < b.component
              ? -1
              : (a.component > b.component ? 1 : 0)))))
        .map((application) => ({
          'automation-id': `navlink-sidebar-${application.id?.replaceAll('.', '-')}${application.component ? `-${application.component.replaceAll('.', '-')}` : ''}`,
          id: application.id,
          hidden: application.hidden ?? false,
          component: application.component,
          message: application.name,
          url: `/apps/${application.id}/${application.component}`,
        })) : []),
  })) : []),
  {
    title: true,
    message: 'common.settings',
  },
  {
    'automation-id': 'navlink-sidebar-system-device',
    icon: 'fa-cog',
    message: 'common.general',
    url: '/device',
  },
  {
    'automation-id': 'navlink-sidebar-system-network',
    icon: 'fe-corporate',
    message: 'common.network',
    url: '/network',
  },
  ...(poe ? [
    {
      'automation-id': 'navlink-sidebar-system-poe',
      icon: 'fa-bolt',
      message: 'common.poe',
      url: '/poe',
    },
  ] : []),
  ...(!storagelessDevice ? [{
    'automation-id': 'dropdown-sidebar-storage',
    icon: 'fa-database',
    message: 'common.storage',
    url: '/storage',
  }] : []),
  {
    'automation-id': 'dropdown-sidebar-system-logs',
    icon: 'fa-book',
    message: 'layout.es.sidebar.logs',
    url: '/logs/device',
  },
  {
    'automation-id': 'navlink-sidebar-system-about',
    icon: 'fa-info-circle',
    message: 'common.about',
    url: '/about',
  },
])

export const navBlueConnect = (poe, storagelessDevice) => ([
  {
    'automation-id': 'navlink-sidebar-dashboard',
    icon: 'fa-tachometer-alt',
    message: 'layout.es.sidebar.dashboard',
    url: '/dashboard',
  },
  {
    title: true,
    message: 'common.system',
  },
  {
    'automation-id': 'navlink-sidebar-system-device',
    icon: 'fa-cogs',
    message: 'common.device',
    url: '/device',
  },
  {
    'automation-id': 'navlink-sidebar-system-network',
    icon: 'fa-globe',
    message: 'common.network',
    url: '/network',
  },
  ...(poe ? [
    {
      'automation-id': 'navlink-sidebar-system-poe',
      icon: 'fa-bolt',
      message: 'common.poe',
      url: '/poe',
    },
  ] : []),
  ...(!storagelessDevice ? [{
    'automation-id': 'dropdown-sidebar-storage',
    icon: 'fa-database',
    message: 'common.storage',
    url: '/storage',
  }] : []),
  {
    'automation-id': 'dropdown-sidebar-system-logs',
    icon: 'fa-book',
    message: 'layout.es.sidebar.logs',
    url: '/logs',
    children: [
      {
        'automation-id': 'navlink-sidebar-system-logs-services',
        message: 'common.services',
        url: '/logs/services',
      },
      {
        'automation-id': 'navlink-sidebar-system-logs-device',
        message: 'common.device',
        url: '/logs/device',
      },
    ],
  },
  {
    'automation-id': 'navlink-sidebar-system-about',
    icon: 'fa-info-circle',
    message: 'common.about',
    url: '/about',
  },
])
