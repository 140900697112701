import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import accServer from './accServer'
import apps from './apps'
import cloud from './cloud'
import network from './network'
import system from './system'
import user from './user'
import webadmin from './webadmin'

const reducer = combineReducers({
  accServer,
  apps,
  cloud,
  network,
  system,
  user,
  webadmin,
  form: formReducer,
})

export default reducer
