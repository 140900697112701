import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Es from './components/Layout/Es'
import L10nProvider from './components/L10nProvider'
import PrivateRoute from './components/PrivateRoute'

import Login from './containers/Login'

import { fetchInfo } from './actions/system'

import fetchInject from './utils/fetch-inject'

class App extends Component {
  constructor(props) {
    super(props)

    props.dispatch(fetchInfo())
    this.state = { cssApplied: false }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProp) {
    const { system } = nextProp
    const { cssApplied } = this.state

    const expectedTitle = `${system.info.productName.replace(/Avigilon\s*/i, '')} - Avigilon`

    if (window.document.title !== expectedTitle) {
      window.document.title = expectedTitle
    }

    if (!cssApplied && !isEmpty(system.info)) {
      if (system.info.cloudDevice) {
        fetchInject(['/css/blue.min.css'])
          .then(() => this.setState({ cssApplied: true }))
      } else {
        this.setState({ cssApplied: true })
      }
    }
  }

  render() {
    const { cssApplied } = this.state

    return cssApplied && (
      <L10nProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={Es} />
          </Switch>
        </BrowserRouter>
      </L10nProvider>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  system: state.system,
})

export default connect(mapStateToProps)(App)
