import {
  handleDeleteResponse,
  handleError,
  handleGetResponse,
  handlePostResponse,
} from '../utils/handleResponse'
import Token from '../utils/Token'

import * as types from '../constants/ActionTypes'

export const fetchDns = () => (dispatch) => (
  fetch('/edge/api/system/network/dns', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.NETWORK_DNS,
      dns: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchPoe = () => (dispatch) => (
  fetch('/edge/api/system/network/poe', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.NETWORK_POE,
      poe: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchSwitch = () => (dispatch) => (
  fetch('/edge/api/system/network/switch', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.NETWORK_SWITCH,
      switch: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchConnection = () => (dispatch) => (
  fetch('/edge/api/system/network/connections', {
    headers: { 'X-Jwt-Token': Token.get() },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.NETWORK_CONNECTION,
      connections: json.connections,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchConnectionHwinfo = (name) => async (dispatch) => {
  const resp = await fetch(`/edge/api/system/network/connections/${name}/hwinfo`, {
    headers: { 'X-Jwt-Token': Token.get() },
  })
  const json = await resp.json()
  return dispatch({
    type: types.NETWORK_HWINFO,
    name,
    hwinfo: json,
  })
}

export const fetchConnectionRoutes = (name) => async (dispatch) => {
  const resp = await fetch(`/edge/api/system/network/connections/${name}/routes`, {
    headers: { 'X-Jwt-Token': Token.get() },
  })
  const json = await resp.json()
  return dispatch({
    type: types.NETWORK_ROUTES,
    name,
    routes: json,
  })
}

export const updateDns = (values) => (dispatch) => (
  fetch('/edge/api/system/network/dns', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      automatic: values.automatic,
      servers: [values.preferred, values.server1, values.server2],
    }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchDns))
    .catch((e) => handleError(e, dispatch))
)

export const updateHostname = (values) => (dispatch) => (
  fetch('/edge/api/system/network/dns', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({ hostname: values.hostname }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchDns))
    .catch((e) => handleError(e, dispatch))
)

export const updatePoe = (values) => (dispatch) => {
  const ports = []
  Object.keys(values).sort().forEach((key) => {
    const { powermode, budget } = values[key]
    ports.push({
      power: powermode === 'auto' || powermode === 'manual',
      budget: powermode === 'auto' ? 0 : budget,
    })
  })

  return fetch('/edge/api/system/network/poe', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({ ports }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchPoe, fetchSwitch))
    .catch((e) => handleError(e, dispatch))
}

function prepareAdvanced(advanced) {
  if (!advanced) {
    return {};
  }

  const { metric, ...rest } = advanced;

  return {
    metric: Number(metric),
    ...rest,
  };
}

function prepareBond(bond) {
  if (!bond) {
    return {};
  }

  return { ...bond };
}

function prepareVlan(vlan) {
  if (!vlan) {
    return {};
  }

  const { vlanID, ...rest } = vlan;

  return {
    vlanID: Number(vlanID),
    ...rest,
  };
}

export const updateConnection = (values) => (dispatch) => {
  // map from
  // {
  //   "name": "enx",
  //   "label": "link0",
  //   ...
  // }
  // back to connection object
  // {
  //   "enx": { ... }
  // }
  const {
    advanced, bond, dns, ip, vlan,
  } = values;
  const { label, name, ...rest } = ip;

  const body = {
    ...rest,
    ...dns,
    ...prepareAdvanced(advanced),
    ...prepareBond(bond),
    ...prepareVlan(vlan),
  }

  return fetch('/edge/api/system/network/connections', {
    method: 'POST',
    headers: { 'X-Jwt-Token': Token.get() },
    body: JSON.stringify({
      connections: {
        [name]: body,
      },
    }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchConnection))
    .catch((e) => handleError(e, dispatch))
}

export const identifyConnection = (name, identify) => async (dispatch) => {
  const resp = await fetch(`/edge/api/system/network/connections/${name}/identify`, {
    method: 'POST',
    headers: { 'X-Jwt-Token': Token.get() },
    body: JSON.stringify({ identify }),
  })
  const json = await resp.json()
  return dispatch({
    type: types.NETWORK_IDENTIFY,
    name,
    isBlinking: json.identify,
  })
}

export const createConnection = (values) => (dispatch) => (
  fetch('/edge/api/system/network/connections', {
    method: 'PUT',
    headers: { 'X-Jwt-Token': Token.get() },
    body: JSON.stringify({ ...values }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchConnection))
    .catch((e) => handleError(e, dispatch))
)

export const deleteConnection = (id) => (dispatch) => (
  fetch(`/edge/api/system/network/connections/${id}`, {
    method: 'DELETE',
    headers: { 'X-Jwt-Token': Token.get() },
  })
    .then((resp) => handleDeleteResponse(resp, dispatch, fetchConnection))
    .catch((e) => handleError(e, dispatch))
)
