import { change, reset } from 'redux-form'

// eslint-disable-next-line import/no-cycle
import { handleError, handleGetResponse } from '../utils/handleResponse'
import Token from '../utils/Token'

import * as types from '../constants/ActionTypes'

const handleAuthResponse = (dispatch, json) => {
  if (json.token) {
    const { token } = json
    let { expiresIn } = json

    // JS works in ms, so we must multiply be 1000
    expiresIn *= 1000
    Token.set(token, expiresIn)
    // and we renew the token a little before it needs to be renewed
    expiresIn *= 0.75

    setTimeout(() => {
      // eslint-disable-next-line no-use-before-define
      dispatch(refresh(false))
    }, expiresIn)

    return dispatch({
      type: types.USER_NEW_TOKEN,
      token,
    })
  }

  throw new Error('invalid auth response')
}

export const changePassword = (values) => (dispatch) => (
  fetch('/edge/api/system/auth/changepassword', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      username: 'administrator',
      password: values.password,
      newPassword: values.newPassword,
    }),
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => {
      dispatch(reset('password'))
      return handleAuthResponse(dispatch, json)
    })
    // don't handleError here. will be handled in LogCard
)

export const fetchInitialized = () => (dispatch) => (
  fetch('/edge/api/system/auth/initialized')
    .then((resp) => handleGetResponse(resp))
    .then((json) => (
      dispatch({
        type: types.USER_INITIALIZED,
        initialized: json.initialized,
      })
    ))
    .catch((e) => handleError(e, dispatch))
)

export const firstTimeInit = (newPassword) => (dispatch) => (
  fetch('/edge/api/system/auth/changepassword', {
    method: 'POST',
    body: JSON.stringify({
      username: 'administrator',
      newPassword,
    }),
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => handleAuthResponse(dispatch, json))
    .catch((e) => handleError(e, dispatch))
)

export const loginFailure = () => (dispatch) => {
  dispatch(change('login', 'password', ''))
  return dispatch({ type: types.USER_LOGIN_FAILURE })
}

export const logout = () => (dispatch) => {
  const token = Token.get()
  Token.remove()

  // server only cares about the valid token on logout
  // so we don't need to post if the token is expired
  if (token) {
    return fetch('/edge/api/system/auth/logout', {
      method: 'POST',
      headers: {
        'X-Jwt-Token': token,
      },
    })
      .then(() => dispatch({ type: types.USER_LOGOUT }))
      .catch((e) => {
        dispatch({ type: types.USER_LOGOUT })
        // eslint-disable-next-line no-console
        console.warn(e.message)
      })
  }

  dispatch({ type: types.USER_LOGOUT })
  return Promise.resolve()
}

// when isRetry is true it will prevent refresh from calling itself over and over
// if there's an error
export const refresh = (isRetry) => (dispatch) => (
  fetch('/edge/api/system/auth/refresh', {
    method: 'GET',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => handleAuthResponse(dispatch, json))
    .catch((e) => {
      // schedule another refresh just before the current token expires
      if (e.status !== 401 && !isRetry) {
        const expiresAt = Token.getExpiresAt();
        if (expiresAt > Date.now() - 500) {
          setTimeout(
            () => dispatch(refresh(true)),
            expiresAt - Date.now() - 500,
          );
        }
      }

      return handleError(e, dispatch)
    })
)

export const login = (username, password) => (dispatch) => {
  dispatch({
    type: types.USER_NEW_TOKEN,
    token: undefined,
  })

  return fetch('/edge/api/system/auth/login', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => handleAuthResponse(dispatch, json))
    .catch(() => dispatch(loginFailure()))
}
