import { isEmpty, startsWith } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'

import { ReactComponent as IcLprIcon } from '../../../assets/img/ic-lpr.svg'
import { ReactComponent as IcServerIcon } from '../../../assets/img/ic-server.svg'
import { ReactComponent as IcAnalyticsIcon } from '../../../assets/img/ic-panel-expand.svg'

import L10nFormattedMessage from '../../L10nFormattedMessage'

import { nav, navBlueConnect } from './nav'

class Sidebar extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props
    if (nextProps.location !== location) {
      document.body.classList.remove('sidebar-mobile-show')
    }
  }

  activeRoute = (routeName, routeId) => {
    const { location: { pathname }, structure } = this.props

    const currentId = pathname.split('/')[2]

    return startsWith(pathname, routeName)
    || currentId === routeId
    || structure[routeId]?.members.some((app) => app.id === currentId)
    || false
  }

  handleClick = (event) => {
    event.preventDefault()
    const { target } = event
    if (target) {
      target.classList.toggle('fa-rotate-90')
    }

    let navTarget = target
    while (navTarget && !navTarget.classList.contains('nav-dropdown')) {
      navTarget = navTarget.parentElement
    }

    if (navTarget) {
      navTarget.classList.toggle('open')
    }
  }

  placeIcon = (icon) => {
    if (icon && icon.startsWith('ic-')) {
      switch (icon) {
        case 'ic-server':
          return (<IcServerIcon />)
        case 'ic-lpr':
          return (<IcLprIcon />)
        case 'ic-analytics':
          return (<IcAnalyticsIcon />)
        default:
          break
      }
    }
    if (icon && icon.startsWith('fe-')) {
      return (<i className={`fes ${icon} fe-fw`} />)
    }
    return (<i className={`fas ${icon} fa-fw`} />)
  }

  navDropdown = (item, index) => (
    <li key={index} className={this.activeRoute(item.url, item.id) ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown'}>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <NavLink
        className="nav-link"
        to={item.url}
        activeClassName="active"
        automation-id={item['automation-id']}
      >
        {this.placeIcon(item.icon)}
        {' '}
        <L10nFormattedMessage
          id={item.id ? `apps.names.${item.id}${item.component ? `.${item.component}` : ''}` : item.message}
          defaultMessage={item.message}
        />
        <i
          onClick={this.handleClick}
          aria-hidden="true"
          style={{ lineHeight: 'unset' }}
          className={this.activeRoute(item.url, item.id) ? 'fas float-right fa-chevron-right fa-fw fa-rotate-90' : 'fas float-right fa-chevron-right fa-fw'}
        />
      </NavLink>
      {/* eslint-enable jsx-a11y/anchor-is-valid */}
      <ul className="nav-dropdown-items">
        {this.navList(item.children)}
      </ul>
    </li>
  )

  navItem = (item, index) => (
    <NavItem key={index}>
      <NavLink
        className="nav-link"
        to={item.url}
        activeClassName="active"
        automation-id={item['automation-id']}
      >
        {this.placeIcon(item.icon)}
        {' '}
        <L10nFormattedMessage
          id={item.id ? `apps.names.${item.id}${item.component ? `.${item.component}` : ''}` : item.message}
          defaultMessage={item.message}
        />
      </NavLink>
    </NavItem>
  )

  navLink = (item, index) => {
    if (item.hidden) {
      return (<React.Fragment key={index} />)
    }
    return (
      item.title
        ? this.navTitle(item, index)
        : item.children && item.children.length > 0
          ? this.navDropdown(item, index)
          : this.navItem(item, index)
    )
  }

  navList = (items) => items.map((item, index) => this.navLink(item, index))

  navTitle = (item, index) => (
    <li key={index} className="nav-title">
      <L10nFormattedMessage
        id={item.message}
        defaultMessage={item.message}
      />
    </li>
  )

  render() {
    const { network, system, structure } = this.props
    const poe = network.poe && !isEmpty(network.poe.ports)

    return (
      <div className="sidebar">
        <nav className="sidebar-nav" automation-id="nav-sidebar">
          <Nav>
            {this.navList(system.info.cloudDevice
              ? navBlueConnect(poe, system.info.storagelessDevice)
              : nav(poe, system.info.storagelessDevice, structure))}
          </Nav>
        </nav>
      </div>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.object.isRequired,
  network: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  structure: PropTypes.object.isRequired,
}

export default Sidebar
