import {
  handleError,
  handleGetResponse,
  handlePostResponse,
} from '../utils/handleResponse'
import Token from '../utils/Token'

import * as types from '../constants/ActionTypes'
import { fetchServices } from './system'

export const fetchAppsPackages = () => (dispatch) => (
  fetch('/edge/api/acc/apps/packages/list', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.APPS_PACKAGES,
      packages: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchAppsCapabilities = (appId, component) => (dispatch) => (
  fetch(`/edge/api/acc/apps/${appId}/capabilities?component=${component || ''}`, {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.APPS_CAPABILITIES,
      capabilities: json,
    }))
    .catch((e) => {
      dispatch({
        type: types.APPS_CAPABILITIES_FAILED,
      })
      return handleError(e, dispatch)
    })
)

export const fetchAppsStructure = () => (dispatch) => (
  fetch('/edge/api/acc/apps/structure', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.APPS_STRUCTURE,
      structure: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchAppsInfo = (appId, component) => (dispatch) => (
  fetch(`/edge/api/acc/apps/${appId}/info?component=${component || ''}`, {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.APPS_INFO,
      info: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchAppsHealth = (appId, component) => (dispatch) => (
  fetch(`/edge/api/acc/apps/${appId}/health?component=${component || ''}`, {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.APPS_HEALTH,
      health: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const toggleApp = (appId, component, running) => (dispatch) => {
  dispatch({
    type: types.SYSTEM_SERVICES_BUSY,
    busy: true,
  })

  dispatch({
    type: types.APPS_ACTION_ONGOING,
    appId,
    component,
    actionType: 'toggle',
  })

  return fetch(`/edge/api/acc/apps/${appId}/control/${running ? 'start' : 'stop'}?component=${component || ''}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(
      resp,
      dispatch,
      fetchServices,
      () => fetchAppsInfo(appId, component),
      () => fetchAppsHealth(appId, component),
    ))
    .then(() => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      dispatch({
        type: types.APPS_ACTION_COMPLETE,
      })
    })
    .catch((e) => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      dispatch({
        type: types.APPS_ACTION_COMPLETE,
      })
      return handleError(e, dispatch)
    })
}

export const restartApp = (appId, component) => (dispatch) => {
  dispatch({
    type: types.SYSTEM_SERVICES_BUSY,
    busy: true,
  })

  dispatch({
    type: types.APPS_ACTION_ONGOING,
    appId,
    component,
    actionType: 'restart',
  })

  return fetch(`/edge/api/acc/apps/${appId}/control/restart?component=${component || ''}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(
      resp,
      dispatch,
      fetchServices,
      () => fetchAppsInfo(appId, component),
      () => fetchAppsHealth(appId, component),
    ))
    .then(() => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      dispatch({
        type: types.APPS_ACTION_COMPLETE,
      })
    })
    .catch((e) => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      dispatch({
        type: types.APPS_ACTION_COMPLETE,
      })
      return handleError(e, dispatch)
    })
}

export const enabledAppVersion = (appId, version) => (dispatch) => {
  dispatch({
    type: types.SYSTEM_SERVICES_BUSY,
    busy: true,
  })

  return fetch(`/edge/api/acc/apps/${appId}/packages/enable/${version}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(
      resp,
      dispatch,
      fetchServices,
      fetchAppsPackages,
    ))
    .then(() => dispatch({
      type: types.SYSTEM_SERVICES_BUSY,
      busy: false,
    }))
    .catch((e) => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      return handleError(e, dispatch)
    })
}

export const removeAppVersion = (appId, version) => (dispatch) => fetch(`/edge/api/acc/apps/${appId}/packages/remove/${version}`, {
  method: 'POST',
  headers: {
    'X-Jwt-Token': Token.get(),
  },
})
  .then((resp) => handlePostResponse(
    resp,
    dispatch,
    fetchServices,
    fetchAppsPackages,
  ))
  .catch((e) => handleError(e, dispatch))

export const clearAppStorage = (appId, component, option) => (dispatch) => {
  dispatch({
    type: types.SYSTEM_SERVICES_BUSY,
    busy: true,
  })

  dispatch({
    type: types.APPS_ACTION_ONGOING,
    appId,
    component,
    actionType: 'clear',
  })

  return fetch(`/edge/api/acc/apps/${appId}/storage/clear?&component=${component}&option=${option}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(
      resp,
      dispatch,
    ))
    .then(() => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      dispatch({
        type: types.APPS_ACTION_COMPLETE,
      })
    })
    .catch((e) => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      dispatch({
        type: types.APPS_ACTION_COMPLETE,
      })
      return handleError(e, dispatch)
    })
}
