import * as types from '../constants/ActionTypes'

const apps = (state = {
  structure: {},
  capabilities: {},
  capabilitiesFailed: false,
  info: {},
  health: {},
  ongoingAction: {
    appId: '',
    component: '',
    type: '',
  },
  packages: {},
}, action) => {
  switch (action.type) {
    case types.APPS_CAPABILITIES:
      return { ...state, capabilities: action.capabilities, capabilitiesFailed: false }
    case types.APPS_CAPABILITIES_FAILED:
      return { ...state, capabilitiesFailed: true }
    case types.APPS_STRUCTURE:
      return { ...state, structure: action.structure }
    case types.APPS_INFO:
      return { ...state, info: action.info }
    case types.APPS_HEALTH:
      return { ...state, health: action.health }
    case types.APPS_ACTION_ONGOING:
      return {
        ...state,
        ongoingAction:
        { appId: action.appId, component: action.component, type: action.actionType },
      }
    case types.APPS_ACTION_COMPLETE:
      return { ...state, ongoingAction: { appId: '', component: '', type: '' } }
    case types.APPS_PACKAGES:
      return { ...state, packages: action.packages }
    default:
      return state
  }
}

export default apps
